import { Location } from "@angular/common";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as fromProfileSettings from "@app/profile/store/selectors";
import { HTTPStatusCode } from "@app/shared/enums/http-status-code";
import * as fromRoot from "@app/store";
import { setRedirectUrl } from "@app/store/actions/auth.actions";
import { TranslocoService } from "@jsverse/transloco";
import { select, Store } from "@ngrx/store";
import { Observable, throwError, timer } from "rxjs";
import { catchError, mergeMap, take } from "rxjs/operators";
import { EXCLUDED_PAGES } from "../consts/excluded-pages.conts";
import { ToastService } from "../services/toast.service";
import { isClientError } from "./helpers/is-client-error.helper";

export const SKIP_ERROR_NOTIFY = "skipErrorNotify";

/**
 * Interceptor for handling HTTP errors and notifications
 *
 * Features:
 * - Handles 401 Unauthorized errors for questionnaire and dashboard routes
 * - Shows error notifications using ToastService
 * - Allows skipping error notifications via SKIP_ERROR_NOTIFY header
 * - Formats error messages for display
 * - Supports nested error arrays
 */
@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  readonly store = inject(Store<fromRoot.State>);
  readonly location = inject(Location);
  readonly router = inject(Router);
  readonly toastr = inject(ToastService);
  readonly translocoService = inject(TranslocoService);

  readonly EXCLUDED_PAGES: string[] = EXCLUDED_PAGES;
  readonly QUESTIONNAIRE_API_PATH = "/api/patient/questionnaire";

  isExcludedPage(page: string) {
    for (const pageUrl of this.EXCLUDED_PAGES) {
      if (page.includes(pageUrl)) {
        return true;
      }
    }

    return false;
  }

  questionaireHandler(request: HttpRequest<any>, next: HttpHandler) {
    return this.store.pipe(
      select(fromProfileSettings.selectAllSettings()),
      mergeMap((settings) => {
        const { hash_id, only } = settings;

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === HTTPStatusCode.UNAUTHORIZED) {
              const queryParams: any = {
                hash_id,
              };
              if (only) {
                queryParams["only"] = only;
              }

              this.router.navigate(["/profile/questions/secret-word"], {
                queryParams,
              });
            }

            return throwError(() => ({
              ...this.errorFormatter(error),
              status: error.status,
              statusText: error.statusText,
            }));
          }),
        );
      }),
    );
  }

  dashboardHandler(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HTTPStatusCode.UNAUTHORIZED) {
          this.store.dispatch(fromRoot.logoutSuccess());
          this.store.dispatch(setRedirectUrl({ url: this.router.url }));
        }

        return throwError(() => ({
          ...this.errorFormatter(error),
          status: error.status,
          statusText: error.statusText,
        }));
      }),
    );
  }

  errorHandler(error: HttpErrorResponse & { errors: string[] }): string {
    const errorMsg = error.message;
    const nestedErrors = error.errors;

    if (typeof errorMsg === "string") {
      this.toastr.error(errorMsg, {}, 7000);
    }

    if (nestedErrors) {
      this.toastr.multipleErrorToast(nestedErrors, {}, 7000);
    }

    return errorMsg;
  }

  fetchErrorMessages(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    timer(0)
      .pipe(take(1))
      .subscribe(() => {
        if (isClientError(error)) {
          this.errorHandler(error as HttpErrorResponse & { errors: string[] });
        } else {
          const errorMsg = this.translocoService.translate(
            "errors.unexpected_error",
          );
          this.toastr.error(errorMsg, {}, 7000);
        }
      });
    console.debug(error);

    return throwError(() => this.errorFormatter(error));
  }

  private errorFormatter(error: HttpErrorResponse) {
    return error.error;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const location = this.location.path();
    const requestUrl = request.url;

    const isNotifyAvailable = !request.headers.get(SKIP_ERROR_NOTIFY);
    const isNotifyBlocked = !isNotifyAvailable;

    if (isNotifyBlocked) {
      request.headers.delete(SKIP_ERROR_NOTIFY);
    }

    // 401 Обработчики
    if (
      this.isExcludedPage(location) &&
      requestUrl.includes(this.QUESTIONNAIRE_API_PATH)
    ) {
      return this.questionaireHandler(request, next).pipe(
        catchError((error) => {
          if (isNotifyAvailable) {
            return this.fetchErrorMessages(error);
          }

          return throwError(() => this.errorFormatter(error));
        }),
      );
    }

    return this.dashboardHandler(request, next).pipe(
      catchError((error) => {
        if (isNotifyAvailable) {
          return this.fetchErrorMessages(error);
        }

        return throwError(() => this.errorFormatter(error));
      }),
    );
  }
}
