import {
  ApplicationConfig,
  importProvidersFrom,
  Injectable,
} from "@angular/core";

import { DOCUMENT } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "@env/environment";
import {
  provideTransloco,
  provideTranslocoScope,
  Translation,
  TranslocoLoader,
} from "@jsverse/transloco";
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";
import amplitude from "@libs/amplitude-js";
import { DialogModule } from "@ngneat/dialog";
import { EffectsModule } from "@ngrx/effects";
import { provideRouterStore } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { QuicklinkStrategy } from "ngx-quicklink";
import { provideToastr } from "ngx-toastr";
import { routes } from "./app.routes";
import {
  AcceptHeaderInterceptor,
  CleanHttpParamsInterceptor,
  ErrorsInterceptor,
} from "./core/interceptors";
import { tokenGetter } from "./core/services/auth.service";
import { effects, metaReducers, ROOT_REDUCERS } from "./store";
import * as fromRouter from "./store/reducers/router.reducer";

amplitude.getInstance().init(environment.amplitude.apiKey, {
  autocapture: { elementInteractions: true },
});

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const applicationConfig: ApplicationConfig = {
  providers: [
    // !Global DOCUMENT
    {
      provide: Document,
      useExisting: DOCUMENT,
    },
    // !HTTP_INTERCEPTORS
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ResponseInterceptor,
    //   multi: true,
    // },
    provideTransloco({
      config: {
        availableLangs: environment.locales,
        defaultLang: environment.defaultLocale,
        fallbackLang: environment.fallbackLang,
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: false,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoScope("errors"),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    // Ngrx
    importProvidersFrom(
      StoreModule.forRoot(ROOT_REDUCERS, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot(effects),

      StoreDevtoolsModule.instrument({
        name: "NGRX Nutrient Planner Store App",
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true,
      }),
    ),
    provideRouterStore({
      stateKey: fromRouter.routerFeatureKey,
      serializer: fromRouter.CustomSerializer,
    }),
    provideToastr({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter,
          allowedDomains: [environment.primaryApiUrl, environment.apiDomain],
          disallowedRoutes: [
            `${environment.primaryApiUrl}/auth/`,
            `${environment.primaryApiUrl}/profile/`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/login`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/patient`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/update`,
          ],
          authScheme: "bearer ",
          skipWhenExpired: true,
        },
      }),
    ),
    importProvidersFrom(DialogModule.forRoot()),
    provideTranslocoMessageformat(),
    importProvidersFrom(
      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: environment.production,
      }),
    ),
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        // enableTracing: true,
        scrollPositionRestoration: "enabled",
        preloadingStrategy: QuicklinkStrategy,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
  ],
};
